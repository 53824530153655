<template>
	<div id="dialog">
		<h3>Share</h3>
		<!-- TO DO: create short urls by adding index to evert itm and calling index.toString(36) -->
		<div ref="ref_urlText" class="url text" :class="{ copied: isCopied2 }" @click="copyUrlText">
			<span v-html="urlTextHtml"></span>
			<BaseIcon name="mark-good" />
			<BaseIcon name="copy" />
		</div>

		<div ref="ref_url" class="url" :class="{ copied: isCopied1 }" @click="copyUrl">
			{{ url }}
			<BaseIcon name="mark-good" />
			<BaseIcon name="copy" />
		</div>

		<!-- Twitter / Facebook / Email -->
		<div class="social-wrap">
			<!-- prettier-ignore -->
			<a :href="`mailto:?subject=${encodeURIComponent(text+' on Arthur.io')}&body=${encodeURIComponent(urlText)}`" class="social" target="_blank"><BaseIcon name="email" /></a>
			<!-- prettier-ignore -->
			<a :href="`https://twitter.com/intent/tweet?text=${encodeURIComponent(urlText)}`" class="social" target="_blank"><BaseIcon name="twitter" /></a>
			<!-- prettier-ignore -->
			<a :href="`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}&quote=${encodeURIComponent(urlText)}`" class="social" target="_blank"><BaseIcon name="facebook" /></a>
			<!-- prettier-ignore -->
			<a :href="`https://www.linkedin.com/cws/share/?url=${encodeURIComponent(url)}`" class="social" target="_blank"><BaseIcon name="linkedin" /></a>
			<!-- prettier-ignore -->
			<a :href="`http://service.weibo.com/share/share.php?url=${encodeURIComponent(url)}&appkey=&title=${encodeURIComponent(text)}&pic=${encodeURIComponent('https://arthur.io/img/art/jpg/000173449e13cd604/kris-martin/vanitas/large/kris-martin--vanitas.webp')}&ralateUid=&language=zh_cn`" class="social" target="_blank"><BaseIcon name="weibo" /></a>
		</div>

		<div class="buttons-wrap">
			<FormButton ref="submit" :level="1" value="Done" @click="doCancel" />
			<a v-if="params.entityType == 'itm'" :href="`/img/download/${params.id}`">
				<FormButton :level="2" value="Download image" />
			</a>
		</div>
	</div>
</template>

<script>
// Vue
import { mapState } from 'pinia'

// Stores
import { useDialogStore } from '@/stores/DialogStore'

// Components
import FormButton from '@/components/FormButton'
import BaseIcon from '@/components/BaseIcon'

// Internal
import copyText from '@/use/CopyText'

export default {
	name: 'DialogShare',
	components: {
		BaseIcon,
		FormButton,
	},
	data() {
		return {
			isCopied1: false,
			isCopied2: false,

			// Defaults (for reference)
			defaultParams: {
				url: null,
				text: null,
				imgUrl: null,
				id: null,
				entityType: null,
			},
		}
	},
	computed: {
		// Pinia state
		...mapState(useDialogStore, ['params', 'doCancel']),
		url() {
			return this.params.url
		},
		text() {
			return this.params.text
		},
		urlText() {
			return this.text + '\n' + this.params.url
		},
		urlTextHtml() {
			return this.text + '<br />' + this.params.url
		},
	},
	created() {
		setTimeout(this.copyUrlText, 0)
	},
	methods: {
		copyUrl() {
			this.isCopied1 = true
			this.isCopied2 = false
			copyText({ text: this.url, elm: this.$refs.ref_url })
		},
		copyUrlText() {
			this.isCopied1 = false
			this.isCopied2 = true
			copyText({ text: this.urlText, elm: this.$refs.ref_urlText })
		},
	},
}
</script>

<style scoped lang="scss">
#dialog {
	width: 2.9rem;
}
// h3 {
// 	color: $good;
// }

/**
 * Url display
 */
#dialog .url {
	border-radius: $br;
	padding: 0.1rem 0.3rem 0.1rem 0.15rem;
	border: solid 0.02rem $black-10;
	position: relative;
	cursor: pointer;
	margin-bottom: 0.3rem;
	font-size: $small;
	line-height: $small-line-height;

	// Truncate
	display: block;
	flex: 1;
	min-width: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

// Copied state
#dialog .url.copied {
	border: solid 0.02rem $good;
}
#dialog .url.copied::after {
	content: 'Link copied';
	color: $good;
	font-size: $small;
	height: 0.2rem;
	line-height: 0.2rem;
	position: absolute;
	top: -0.25rem;
	right: 0;
}

// Icon
#dialog .url:deep() .icn {
	position: absolute;
	top: 0.05rem;
	right: 0.05rem;
	display: none;
}
#dialog .url:not(.copied):deep() .icn-copy {
	display: block;
}
#dialog .url.copied:deep() .icn-mark-good {
	display: block;
	stroke: $good;
}

/**
 * Social button
 */
#dialog .social-wrap {
	display: flex;
}
#dialog .social {
	width: 0.5rem;
	height: 0.5rem;
	background: $black-05;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.1rem;
	border-radius: $br;
}
#dialog .social:last-child {
	margin-right: 0;
}

@media (hover: hover) {
	#dialog .url:not(.copied):hover {
		border: dashed rgba($good, 0.2) 0.02rem;
	}
	#dialog .social:hover {
		background: $primary;
	}
}
</style>
